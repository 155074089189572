@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@layer base {
  body {
    @apply text-text bg-slate-50 dark:bg-dark-light dark:text-slate-50;
  }

  h1 {
    @apply text-2xl font-bold
    md:text-3xl;
  }

  h2 {
    @apply text-xl font-semibold
    md:text-2xl;
  }

  h3 {
    @apply text-lg font-medium
    md:text-xl;
  }
}

@layer components {
  .text-link {
    @apply text-blue-600 no-underline
    hover:underline font-semibold;
  }

  .text-link-light {
    @apply text-text-light underline font-normal
    hover:decoration-2;
  }

  .input-shape {
    @apply block w-full rounded-lg px-3 py-2;
  }

  .input-color {
    @apply border-default
  focus:border-button focus:ring focus:ring-button-light focus:ring-opacity-50
  focus-within:border-button focus-within:ring focus-within:ring-button-light focus-within:ring-opacity-50
  dark:bg-dark-light
  dark:placeholder:text-slate-400;
  }

  .input-disabled {
    @apply text-text-light bg-slate-100 cursor-not-allowed pointer-events-none focus:border-button-disabled
    dark:opacity-60;
  }

  .input-box--error {
    @apply border-red-300 focus:border-red-500 focus:ring-red-200 focus-within:border-red-500 focus-within:ring-red-200;
  }

  .shadow-default {
    @apply shadow-sm shadow-slate-300 dark:shadow-dark-deep;
  }

  .shadow-active {
    @apply shadow-md shadow-slate-300 dark:shadow-dark-deep;
  }

  .shadow-default-hoverable {
    @apply shadow-sm hover:shadow-lg shadow-slate-300 hover:shadow-slate-300 transition-shadow
    dark:shadow-slate-900 dark:hover:shadow-slate-900;
  }

  .shadow-primary-hoverable {
    @apply shadow-sm hover:shadow-lg shadow-blue-300 hover:shadow-blue-300 transition-shadow;
  }

  .shadow-danger-hoverable {
    @apply shadow-sm hover:shadow-lg shadow-red-300 hover:shadow-red-300 transition-shadow;
  }

  .shadow-warning-hoverable {
    @apply shadow-sm hover:shadow-lg shadow-orange-300 hover:shadow-orange-300 transition-shadow;
  }

  .shadow-success-hoverable {
    @apply shadow-sm hover:shadow-lg shadow-green-300 hover:shadow-green-300 transition-shadow;
  }

  .shadow-disabled {
    @apply shadow-none hover:shadow-none;
  }

  .border-default {
    @apply border border-slate-300 dark:border-slate-900;
  }

  /* .my-react-select-container {
  } */
  .my-react-select-container .my-react-select__control {
    @apply rounded-lg bg-white dark:bg-slate-700
    border-slate-300 dark:border-slate-900
    cursor-pointer;
  }

  .my-react-select-container--error .my-react-select__control {
    @apply border-red-500 hover:border-red-500
    dark:border-red-500 dark:hover:border-red-500;
  }

  .my-react-select-container .my-react-select__control--is-focused {
    @apply border-blue-700 dark:border-blue-700;
  }

  .my-react-select-container--error .my-react-select__control--is-focused {
    @apply border-red-500 dark:border-red-500;
  }

  .my-react-select-container .my-react-select__menu {
    @apply bg-white dark:bg-dark-light rounded-lg
    border-0 p-0 shadow-none;
  }

  .my-react-select-container .my-react-select__menu-list {
    @apply p-0 rounded-lg border border-slate-200 dark:border-dark-deep;
  }

  .my-react-select-container .my-react-select__option {
    @apply text-text bg-white hover:bg-blue-50 
    dark:bg-dark-light dark:text-slate-50 dark:hover:bg-slate-500 cursor-pointer;
  }

  .my-react-select-container .my-react-select__option--is-selected {
    @apply bg-blue-700 text-white hover:bg-blue-700 hover:text-white 
    dark:bg-blue-700 dark:text-white dark:hover:bg-blue-700 dark:hover:text-white;
  }

  .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-blue-800 text-white hover:bg-blue-800 hover:text-white 
    dark:bg-blue-800 dark:text-white dark:hover:bg-blue-800 dark:hover:text-white;
  }

  .my-react-select-container .my-react-select__indicator-separator {
    @apply invisible;
  }

  .my-react-select-container .my-react-select__dropdown-indicator {
    @apply text-text-light hover:text-text-light dark:text-slate-50 dark:hover:text-slate-50;
  }

  .my-react-select-container .my-react-select__value-container {
    @apply p-0 m-0;
  }

  .my-react-select-container .my-react-select__multi-value {
    @apply rounded-lg pl-3 flex items-center gap-2 bg-blue-100 text-blue-800;
  }

  .my-react-select-container .my-react-select__multi-value__label {
    @apply p-0 py-2.5 text-base leading-none text-blue-800;
  }

  .my-react-select-container .my-react-select__multi-value__remove {
    @apply rounded-full aspect-square;
  }

  .my-react-select-container .my-react-select__control--is-disabled {
    @apply opacity-50;
  }

  .my-react-select-container .my-react-select__input-container,
  .my-react-select-container .my-react-select__placeholder,
  .my-react-select-container .my-react-select__single-value {
    @apply py-2 pl-3 m-0 text-text dark:text-slate-50;
  }

  /* react-mentions */
  .mentions .mentions__input {
    @apply outline-none;
  }

  .mentions .mentions__suggestions__list {
    @apply bg-white border border-slate-200 dark:bg-slate-900 dark:border-slate-950;
  }

  .mentions .mentions__suggestions__item {
    @apply px-3 py-1;
  }

  .mentions .mentions__suggestions__item--focused {
    @apply bg-orange-200 dark:bg-orange-600;
  }
}
